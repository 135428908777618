import * as deaddrop from '@mmcgill/webrtc-deaddrop-client';

//const apiUrl = 'http://localhost:8080/graphql';
const apiUrl = 'https://t8xssmib2j.execute-api.us-east-2.amazonaws.com/graphql';

const startPublicBtn = document.getElementById('startPublic') as HTMLButtonElement;
const startPrivateBtn = document.getElementById('startPrivate') as HTMLButtonElement;
const joinToken = document.getElementById('joinToken') as HTMLInputElement;
const joinBtn = document.getElementById('join') as HTMLButtonElement;
const joinTokenOutput = document.getElementById('joinTokenOutput') as HTMLInputElement;
const copyJoinTokenBtn = document.getElementById('copyJoinToken') as HTMLButtonElement;
const chatArea = document.getElementById('chatArea') as HTMLTextAreaElement;
const disconnectBtn = document.getElementById('disconnect') as HTMLButtonElement;
const message = document.getElementById('message') as HTMLInputElement;

const disconnectedDiv = document.getElementById('disconnected') as HTMLDivElement;
const waitingDiv = document.getElementById('waiting') as HTMLDivElement;
const chattingDiv = document.getElementById('chatting') as HTMLDivElement;

var pc: RTCPeerConnection | null = null;
var chan: RTCDataChannel | null = null;

const ddClient = new deaddrop.Client({url: apiUrl, domain: 'webchat'});

function connectionTearDown() {
    pc = null;
    chan = null;
    joinBtn.disabled = false;
    disconnectedDiv.style.display = 'block';
    waitingDiv.style.display = 'none';
    chattingDiv.style.display = 'none';
}

function initPeerConnection() {
    const pc = new RTCPeerConnection({iceServers: [{urls: 'stun:stun.l.google.com:19302'}]});
    pc.onconnectionstatechange = function(e) {
        if (!pc) return;
        console.log(`connectionState: ${pc.connectionState}`);
        if (pc.connectionState === 'disconnected' || pc.connectionState === 'failed') {
            connectionTearDown();
        }
    }
    return pc;
}

function sendMessage() {
    if (!chan) return;
    if (message.value === '') return;
    const msg = message.value + '\n';
    message.value = '';
    chatArea.value += 'Me: '+msg;
    chan.send(msg);
}

message.addEventListener('keyup', function(event) {
    if (event.key === 'Enter') {
        sendMessage();
    }
});

function initChan() {
    chatArea.value = '';
    if (!chan) return;
    chan.onopen = function(e) {
        console.log(`channel open`);
        disconnectedDiv.style.display = 'none';
        waitingDiv.style.display = 'none';
        chattingDiv.style.display = 'block';
    }
    chan.onclose = function (e) {
        console.log(`channel closed`);
        connectionTearDown();
    }
    chan.onmessage = function(e) {
        chatArea.value += 'Them: '+e.data;
    } 
}

copyJoinTokenBtn.onclick = function(e) {
    joinTokenOutput.select();
    document.execCommand('copy');
}

async function startChat(isPublic: boolean) {
    console.log('starting chat');
    disconnectedDiv.style.display = 'none';
    copyJoinTokenBtn.disabled = true;
    joinToken.value = "";
    waitingDiv.style.display = 'block';

    try {
        pc = initPeerConnection();
        chan = pc.createDataChannel('chat');
        initChan();
        var deadDrop = await ddClient.offer(pc, {isPublic});
        joinTokenOutput.value = deadDrop.id;
        copyJoinTokenBtn.disabled = false;
        // wait for answer from connecting peer
        await ddClient.pollForAnswer(pc, deadDrop);
    } catch (error) {
        disconnectedDiv.style.display = 'block';
        waitingDiv.style.display = 'none';
        chattingDiv.style.display = 'none';
        throw error;
    }
}
startPublicBtn.onclick = async (e) => startChat(true);
startPrivateBtn.onclick = async(e) => startChat(false);

async function joinChat() {
    console.log('joining session');
    try {
        joinBtn.disabled = true;
        pc = initPeerConnection();
        pc.ondatachannel = (e) => {
            console.log(`got data channel`);
            chan = e.channel;
            initChan();
        };
        await ddClient.answer(pc, joinToken.value);
    } catch (error) {
        console.log(error);
        disconnect();
    } finally {
        joinToken.value = '';
    }
}
joinBtn.onclick = joinChat;

function disconnect() {
    if (pc) {
        console.log('disconnecting');
        pc.close();
        connectionTearDown();
    }
}
disconnectBtn.onclick = disconnect;
